<template>
    <!-- 导航 -->
    <page-head title="精彩瞬间"/>
    <content-bg>
        <template v-slot:content>
            <p class="title">精彩瞬间</p>
            <el-carousel indicator-position="none" class="carousel">
                <el-carousel-item v-for="item in imgList" :key="item">
                    <img style="height: 100%" :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </template>
    </content-bg>
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs, onMounted, watch} from "vue";
import {useStore} from "vuex";

export default {
    name: "moment",

    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            imgList: [
                require('@i/introduce/moment/1.jpg'),
                require('@i/introduce/moment/2.jpg'),
                require('@i/introduce/moment/3.jpg'),
                require('@i/introduce/moment/4.jpg'),
                require('@i/introduce/moment/5.jpg'),
                require('@i/introduce/moment/6.jpg'),
                require('@i/introduce/moment/7.jpg'),
                require('@i/introduce/moment/8.jpg'),
                require('@i/introduce/moment/9.jpg'),
                require('@i/introduce/moment/10.jpg'),
                require('@i/introduce/moment/11.jpg'),
                require('@i/introduce/moment/12.jpg'),
                require('@i/introduce/moment/13.jpg'),
                require('@i/introduce/moment/14.jpg'),
                require('@i/introduce/moment/15.jpg'),
                require('@i/introduce/moment/16.jpg'),
            ]
        });

        return{
            ...toRefs(state)
        }

    },
}
</script>

<style scoped lang="scss">
.title {
    padding-top: 44px;
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
}

.carousel {
    text-align: center;
    height: 608px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
}
</style>